<template>
    <div v-if="loading">
        <Loading :active.sync="loading" color="#042446"></Loading>
    </div>
    <div class="scope" v-else>
        <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
            <Login />
        </b-modal>
        
    </div>
</template>
<script>
import axios from 'axios'
import router from './../../router/index'
import Login from "./../pages/Login"
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            loading: 'GET_LOADING',
            mode: 'GET_MODE'
        })
    },
    components: {
        Login
    },
    async mounted() {
        this.SET_LOADING_STATUS(true)
        if (this.isLogin) {
            this.getNovelDetail(true)
        } else {
            this.getNovelDetail(false)
        }
        this.getPackChapter()
        this.checkShelf(this.$route.params.novel_id)
    },
    data() {
        return {
            isLoadData: false,
            isLogin: localStorage.getItem('UserInfo') != null,
            novelDetail: {},
            chapter: [{
                chapter_ep_first: "",
                chapter_ep_last: 0,
                chapter_price: 0
            }],
            isShowChapter: false,
            fields: [{
                    key: 'chapter_ep_first',
                    label: ''
                },
                {
                    key: 'chapter_ep_last',
                    label: ''
                },
                {
                    key: 'chapter_price',
                    label: ''
                }
            ],
            spin: false,
            isBuy: false,
            goldEnough: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showLoginModal: false,
            detailForModal: ""
            }
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD', 'SET_LOADING_STATUS']),
        async getNovelDetail(isLogin) {
            if (isLogin) {
                const formData = new FormData();
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                try {
                    await axios.post('https://api2.novelrealm.com/module/novel&submodule=detail&novel_id=' + this.$route.params.novel_id, formData)
                        .then((res) => {
                            //console.log('getNovelDetail : ', res.data);
                            this.novelDetail = res.data
                            //document.title = this.novelDetail.novel_title + " | NovelRealm"
                            this.SET_LOADING_STATUS(false)
                            if (this.novelDetail.buy_book == false) {
                                this.isBuy = false
                            } else {
                                this.isBuy = true
                            }
                        })
                } catch (err) {
                    //console.log('FAILURE!!' + err)
                }
            } else {
                const {
                    data
                } = await axios.get('https://api2.novelrealm.com/module/novel&submodule=detail&novel_id=' + this.$route.params.novel_id)
                this.novelDetail = data
                this.SET_LOADING_STATUS(false)
            }
            for (var n = 0; n < this.novelDetail.novel_count / 50; n++) {
                this.options.push({
                    value: n,
                    text: n + 1
                })
            }
            
            setTimeout(() => {
                this.checkMode()
            }, 5);
        },

        async getPackChapter(){
            this.isLoadData = true
            const formData = new FormData();
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                const {
                    data
                } = await axios.post('https://api2.novelrealm.com/module/novel&submodule=chapter_pack&novel_id=' + this.$route.params.novel_id + '&start=' + this.selected * 50 + '&limit=50', formData)
                this.chapter = data
                this.isLoadData = false
            }
            else{
                const {
                    data
                } = await axios.get('https://api2.novelrealm.com/module/novel&submodule=chapter_pack&novel_id=' + this.$route.params.novel_id + '&start=' + this.selected * 50 + '&limit=50')
                this.chapter = data
                this.isLoadData = false
            }
            this.checkMode()
        },

        async checkBuyChapter(chapter) {
            if (chapter.chapter_price != 0 && this.isLogin != true) {
                this.showLoginModal = true
            } else {
                const formData = new FormData();
                if (this.isLogin) {
                    formData.append('auth_memberid', this.userInfo.auth_memberid);
                    formData.append('auth_reference', this.userInfo.auth_token);
                }
                formData.append('chapter_id', chapter.chapter_id);
                try {
                    await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buypackcheck', formData)
                        .then((res) => {
                            //console.log('buy check : ', res)
                            if (res.data.status == 200) {
                                router.push(window.location.pathname + "/chapter/" + res.data.chapter_id);
                            } else if (res.data.status == 404) {
                                this.detailForModal = {
                                    novel_id: this.novelDetail.novel_id,
                                    novel_cover: this.novelDetail.novel_cover,
                                    last_ep: chapter.chapter_ep,
                                    chapter_id: chapter.chapter_id,
                                    chapter_name: chapter.chapter_name,
                                    chapter_price: chapter.chapter_price,
                                    member_gold: res.data.member_gold
                                }
                                this.showBuyChapterModal = true
                            }
                        })
                } catch (err) {
                    //console.log('FAILURE!!' + err)
                }
            }

        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('componentScope1').classList.remove('darkmode')
            document.getElementById('componentScope2').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('componentScope1').classList.add('darkmode')
            document.getElementById('componentScope2').classList.add('darkmode')
        }
    }
}
</script>


<style scoped>
.scope {
    margin: 3% 10% 3% 10%;
}

.componentScope {
    background-color: #ffffff;
    padding: 2.5% 1%;
}

.darkmode {
    background-color: #35363A;
}

.novelCover {
    border-radius: 5px;
    width: 250px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.divider {
    margin: 10px 0;
}

.btn {
    border-radius: 7px;
    padding: 3px 10px;
    text-align: center;
    color: #ffffff;
}

.btn:hover {
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
}

.continueRead {
    background-color: #F5D087;
}

.continueRead:hover {
    background-color: #FAC762;
}

.addToShelf {
    background-color: #EE806B;
}

.addToShelf:hover {
    background-color: rgb(235, 116, 92);
}

.buyNovel {
    background-color: #042446;
}

.buyNovel:hover {
    background-color: #000a14;
}

.continueRead:hover,
.addToShelf:hover,
.buyNovel:hover,
/* .searchIcon:hover, */
.chapter:hover,
#textReadMore:hover {
    cursor: pointer;
    font-weight: bold;
}

.fontsize {
    font-size: 16px;
}

#textReadMore {
    margin: 0% 2% 0% 2%;
    font-size: 14px;
    color: #EE806B;
}

.desc {
    margin: 2% 2% 0% 2%;
    /* display: block; */
    height: 9em;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.descActive {
    height: fit-content;
    /* max-height: 500px; */
    transition: max-height 0.25s ease-in;
}

.headTable {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 0.5px solid rgba(143, 143, 143, 0.541);
}

/* .searchIcon {
    color: #ffffff;
    background-color: #F5D087;
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
} */

/* .searchIcon:hover {
    background-color: #FAC762;
} */

.chaptertable {
    font-size: 15px;
}

.chapter {
    transform: translateZ(0px);
    transition-duration: .5s;
}

.chapter:hover {
    background-color: rgba(238, 129, 107, 0.212);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.062);
    border-radius: 6px;
    transform: scale(1.01);
}

.chapterLength {
    font-size: 12px;
    color: #ADADAD;
    margin-top: -3px;
}

.verticalCenter {
    margin-top: auto;
    margin-bottom: auto;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.warn {
    font-size: 14px;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #042446;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.bougth {
    color: #ffffff;
    padding: 3px;
    border-radius: 4px;
    background-color: #EE806B;
}

.bi-check {
    background: #EE806B;
    color: white;
    border-radius: 3px;
    font-size: 18px;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .scope {
        margin: 2% 2% 2% 2%;
        padding: 2%;
    }

    .componentScope {
        padding: 8% 1%;
    }

    .novelCover {
        margin-bottom: 2%;
        border-radius: 3px;
        width: 130px;
    }

    .img {
        text-align: center;
        margin-bottom: 4%;
    }

    .title {
        font-size: 15px;
    }

    .chaptertable,
    .fontsize {
        font-size: 14px;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        margin: 3% 20% 3% 20%;
    }
}
</style>
